import React from "react";
import Seo from "../components/seo";

const SupportPage = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <Seo />
      <h1>My PrEP</h1>
      <h2>Support</h2>
      <p>
        If you encounter any problem, use the "Contact" button from the
        "Settings" tab in the app. You can also send an email to{" "}
        <a
          title="Contact the developer"
          href="mailto:contact@myprep.app"
          style={{ color: "black" }}
        >
          contact@myprep.app
        </a>
        .
      </p>
      <p>
        En cas de problème, utilisez le bouton "Contact" depuis l’onglet
        "Réglages" de l’application. Vous pouvez aussi directement envoyer un
        mail à{" "}
        <a
          title="Contacter le développeur"
          href="mailto:contact@myprep.app"
          style={{ color: "black" }}
        >
          contact@myprep.app
        </a>
        .
      </p>
    </div>
  );
};

export default SupportPage;
